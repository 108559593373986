// @generated by protoc-gen-es v2.2.0
// @generated from file hmm/ninja/panels.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, messageDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";
import { file_google_type_money } from "../../google/type/money_pb";

/**
 * Describes the file hmm/ninja/panels.proto.
 */
export const file_hmm_ninja_panels = /*@__PURE__*/
  fileDesc("ChZobW0vbmluamEvcGFuZWxzLnByb3RvEglobW0ubmluamEiLgoFUGFuZWwSCgoCaWQYASABKAkSCwoDc3JjGAIgASgJEgwKBG5hbWUYAyABKAki+gUKC1BhbmVsQ29uZmlnEhAKCHBhbmVsX2lkGAEgASgJEhEKCXN1cnZleV9pZBgCIAEoCRItCgZzdGF0dXMYCiABKA4yHS5obW0ubmluamEuUGFuZWxDb25maWcuU3RhdHVzEhkKEWZpbmFuY2VfZW50aXR5X2lkGAwgASgJEhcKD3JlcXVpcmVkX3NhbXBsZRgDIAEoBRI+Cg9yZWRpcmVjdF90b2tlbnMYBCABKAsyJS5obW0ubmluamEuUGFuZWxDb25maWcuUmVkaXJlY3RUb2tlbnMSGAoQZGlzdHJpYnV0aW9uX3VybBgFIAEoCRIPCgdjb250YWN0GAcgASgJEhEKCXJlZmVyZW5jZRgIIAEoCRIKCgJwbxgJIAEoCRIoCgxmb3JlY2FzdF9jcGkYGSABKAsyEi5nb29nbGUudHlwZS5Nb25leRIpCg1mb3JlY2FzdF9mZWVzGB4gASgLMhIuZ29vZ2xlLnR5cGUuTW9uZXkSKQoNZm9yZWNhc3RfY29zdBgfIAEoCzISLmdvb2dsZS50eXBlLk1vbmV5EiwKDGludm9pY2VkX2NwaRgjIAEoCzISLmdvb2dsZS50eXBlLk1vbmV5QgIYARItCg1pbnZvaWNlZF9mZWVzGCggASgLMhIuZ29vZ2xlLnR5cGUuTW9uZXlCAhgBEi0KDWludm9pY2VkX2Nvc3QYKSABKAsyEi5nb29nbGUudHlwZS5Nb25leUICGAESGAoMaW52b2ljZV9wYWlkGC0gASgIQgIYARIZCg1iaWxsX3JlY2VpdmVkGC4gASgIQgIYARpKCg5SZWRpcmVjdFRva2VucxISCgpxdW90YV9mdWxsGAEgASgJEhIKCnNjcmVlbl9vdXQYAiABKAkSEAoIY29tcGxldGUYAyABKAkiRgoGU3RhdHVzEhIKDlNUQVRVU19VTktOT1dOEAASEwoPU1RBVFVTX0ZPUkVDQVNUEAoSEwoPU1RBVFVTX1JFUE9SVEVEEBRKBAgLEAxCL1otZ2l0aHViLmNvbS9odW1hbi1tYWRlLW1hY2hpbmUvaG1tL25pbmphO25pbmphYgZwcm90bzM", [file_google_type_money]);

/**
 * Describes the message hmm.ninja.Panel.
 * Use `create(PanelSchema)` to create a new message.
 */
export const PanelSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 0);

/**
 * Describes the message hmm.ninja.PanelConfig.
 * Use `create(PanelConfigSchema)` to create a new message.
 */
export const PanelConfigSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 1);

/**
 * Describes the message hmm.ninja.PanelConfig.RedirectTokens.
 * Use `create(PanelConfig_RedirectTokensSchema)` to create a new message.
 */
export const PanelConfig_RedirectTokensSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 1, 0);

/**
 * Describes the enum hmm.ninja.PanelConfig.Status.
 */
export const PanelConfig_StatusSchema = /*@__PURE__*/
  enumDesc(file_hmm_ninja_panels, 1, 0);

/**
 * @generated from enum hmm.ninja.PanelConfig.Status
 */
export const PanelConfig_Status = /*@__PURE__*/
  tsEnum(PanelConfig_StatusSchema);

